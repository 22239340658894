<template>
  <v-container class="p-relative" v-if="items">
    <v-img class="blob blob-1" :src="require('@/assets/blobs/4.svg')"></v-img>
    <v-img class="blob blob-2" :src="require('@/assets/blobs/5.svg')"></v-img>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="5"
        md="4"
        v-for="(card, index) in items"
        :key="index"
      >
        <router-link :to="{name: 'article',params: {slug: card.slug}}">
        <static-card :title="card.title" :background="card.image_card">
        </static-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StaticCard from "@/components/StaticCard";
import articleApi from "@/api/article";
export default {
  components: { StaticCard },
  data: () => {
    return {
      items: [],
    };
  },
  mounted() {
      this.fetchArticles();
  },
  methods: {
    async fetchArticles() {
      try {
        this.loading = true;
        const res = await articleApi.index(1, 2);
        this.items = res.data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blob-1 {
    top: -60px;
    left: -80px;
    width: 516px;
    height: 513px;
  }
  .blob-2 {
    top: 360px;
    left: auto;
    right: -180px;
    width: 779px;
  }
</style>