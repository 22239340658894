<template>
  <paginated :name="name" :displayName="displayName" :perPage="perPage" :apiName="apiName" :singularName="singularName">
    <template #default="{items}">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="3"
          v-for="(item, key) of items"
          :key="key"
        >
         <campaign :item="item"/>
        </v-col>
      </v-row>
    </template>
  </paginated>
</template>

<script>
import Campaign from '@/components/Campaign'
import Paginated from "@/components/Paginated";
import moment from "moment";

export default {
  components: { Campaign, Paginated },
  mounted() {
  },
  watch: {},
  methods: {
    momentDate(date) {
      if (date) {
        return moment(date).locale("tr").format("Do MMMM, YYYY");
      }
      return "";
    },
  },
  computed: {},
  props: {
    perPage: Number,
    name: String,
    displayName: String,
    apiName: String,
    singularName: String,
  },
};
</script>

<style scoped>
.loading-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
</style>