<template>
  <div class="home">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" lg="9">
          <slider/>

        </v-col>
      </v-row>
      
    </v-container>

    <div class="my-8"></div>
    <double-static-card />

    <div class="my-8"></div>

    <v-container class="p-relative">
      <campaign-carousel />
    </v-container>
    <v-container class="p-relative">
      <location-carousel/>
    </v-container>
    <div class="my-8"></div>
    <v-container class="p-relative">
      <v-img class="blob blob-3" :src="require('@/assets/blobs/6.svg')"></v-img>
      <event-carousel />
    </v-container>
    <div class="my-8"></div>
    <v-container class="p-relative">
      <article-carousel />
    </v-container>
  </div>
</template>

<script>
import DoubleStaticCard from "@/components/DoubleStaticCard";
import CampaignCarousel from "@/components/CampaignCarousel";
import EventCarousel from "@/components/EventCarousel";
import ArticleCarousel from "@/components/ArticleCarousel";
import Slider from '@/components/Slider'
import LocationCarousel from '@/components/LocationCarousel.vue';
export default {
  name: "Home",
  components: {
    DoubleStaticCard,
    ArticleCarousel,
    CampaignCarousel,
    EventCarousel,
    Slider,
    LocationCarousel,
  },
  data: () => ({
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
  }),

  methods: {
    getImgUrl(url) {
      return require("@/" + url + "");
    },
  },
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: 1.35rem;
  line-height: 21px;
  color: #cc0066;
  margin-bottom: 3rem;
}
*::v-deep {
  .blob-3 {
    top: 90px;
    left: 260px;
    width: 470px;
  }
  
}
</style>
