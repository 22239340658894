
import axios from '@/plugins/get-axios';
const URL_CAMPAIGN_INDEX = '/campaigns';
const URL_CAMPAIGN_READ = '/campaigns/{slug}';


function index(page,per_page=4) {
    return axios.get(URL_CAMPAIGN_INDEX, {
        params: {
            page,per_page
        }
    });
}

function read(slug) {
    return axios.get(URL_CAMPAIGN_READ.replace('{slug}',slug), {});
}

export default { index,read };
export { URL_CAMPAIGN_INDEX, URL_CAMPAIGN_READ };