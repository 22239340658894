<template>
  <div >
    <v-row class="mb-8" justify="space-between" align="center">
      <v-col cols="auto">
        <h1 class="heading mb-0">Haberler</h1>
      </v-col>
      <v-col cols="auto" v-if="articles && articles.length > 0">
        <router-link :to="{ name: 'articles' }"> Tümünü Gör </router-link>
      </v-col>
    </v-row>

    <slick-carousel :config="slick" :loading="loading" v-if="articles && articles.length > 0">
      <horizontal-card
        v-for="(article, key) of articles"
        :key="key"
        :title="article.title"
        :background="article.image_card"
        :highlight="true == true ? '' : momentDate(article.publish_date)"
        @activate="goToLink(article.slug)"
      >
      </horizontal-card>
    </slick-carousel>
    <div class="nothing" v-else>
        <h4 class="ml-2">Henüz kayıtlı bir haber bulunmamaktadır.</h4>
    </div>
  </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard";
import SlickCarousel from "@/components/SlickCarousel";
import articleApi from "@/api/article";
import moment from "moment";
export default {
  components: { HorizontalCard, SlickCarousel },
  mounted() {
    this.fetchArticles();
  },
  data() {
    return {
      loading: true,
      articles: null,

      slick: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",

        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    momentDate(date) {
      if (date) {
        return moment(date).locale("tr").format("Do MMMM, YYYY");
      }
      return "";
    },
    async fetchArticles() {
      try {
        this.loading = true;
        const res = await articleApi.index(1, 15);
        this.articles = res.data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    goToLink(slug) {
      this.$router.push({ name: "article", params: { slug: slug } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>