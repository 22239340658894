
import axios from 'axios'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/web`
axios.interceptors.request.use(
    async config => {
        
        config.headers = {
            'Accept': 'application/json',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


export default axios;