
import axios from '@/plugins/get-axios';
const URL_LOCATION_INDEX = '/locations';
const URL_LOCATION_READ = '/locations/{slug}';


function index(page,per_page=4) {
    return axios.get(URL_LOCATION_INDEX, {
        params: {
            page,per_page
        }
    });
}

function read(slug) {
    return axios.get(URL_LOCATION_READ.replace('{slug}',slug), {});
}

export default { index,read };
export { URL_LOCATION_INDEX, URL_LOCATION_READ };