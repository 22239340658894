<template>
  <v-hover>
  <template v-slot:default="{ hover }">
    
     <v-card :class="`mx-auto p-relative cursor transition-swing elevation-${hover ? 6 : 2}`" style="border-radius: 10px">
    <v-responsive :aspect-ratio="1">
      <v-img
        class="white--text align-end"
        height="100%"
        :src="background"
      >
      </v-img>
      <header>
        <v-img :src="require('@/assets/blobs/white.svg')" />

        <h2 v-html="title"></h2>
      </header>
    </v-responsive>
  </v-card>
  </template>
  </v-hover>
 
</template>

<script>
export default {
  props: ["title", "background"],
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.cursor {
  cursor: pointer;
}
*::v-deep {
  $width: 110%;

  header {
    position: absolute;
    top: 0;
    left: 0;
    .v-image {
      width: $width;
    }
    h2 {
      font-size: 16px;
      height: $width/2.2;
      width: $width/1.2;
      color: #006f7e;
      position: absolute;
      top: $width/3.2;
      left: 5px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    header {
      $width: 90%;
      .v-image {
        width: $width;
      }
      h2 {
        height: $width/2.2;
        width: $width/1.1;
        top: $width/3.2;
        font-size: 14px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    header {
      $width: 70%;
      .v-image {
        width: $width;
      }
      h2 {
        height: $width/2.2;
        width: $width/1.1;
        top: $width/3.2;
        font-size: 11px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    header {
      $width: 120%;
      .v-image {
        width: $width;
      }
      h2 {
        height: $width/2.2;
        width: $width/1.2;
        top: $width/5;
        font-size: 16px;
      }
    }
  }
}
</style>