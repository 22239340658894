import moment from "moment";
export default {
    methods: {
        prefixDays(item) {
            if (item) {
                switch (item.status) {
                    case 'scheduled':
                        return 'Başlamasına';
                    case 'started':
                        return 'Bitmesine';
                    default:
                        break;
                }
            }
            return '';
        },
        daysRemaining(item) {
            const dt = item.status == 'scheduled' ? item.start_date : item.end_date;
            let eventdate = moment(dt)
            let todaysdate = moment().locale("tr");
            return eventdate.diff(todaysdate, "days");
        },
        prettyDays(item) {
            if(item) {
                const days = this.daysRemaining(item);
                if(days > 0) {
                    return `${this.prefixDays(item)} ${days} gün kaldı`;
                }
                return 'Son gün !';
            }
            return '';
        }
    }
}