<template>
  <v-app class="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view class="rw"></router-view>
    </transition>
    <Footer />
  </v-app>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: 'App',

  components: { Header, Footer },

  data: () => ({
    //
  }),
}
</script>

<style lang="scss" scoped>
.app {
  max-width: 100vw;
  overflow: hidden;
}
.rw {
  z-index: 1;
  min-height: 40vh;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
