<template>
  <main>
    <v-container class="mb-2">
      <h1 class="heading">KVKK Aydınlatma Metni</h1>
    </v-container>
    <v-container class="content">
      <section class="blob-content p-relative">
        <section class="numbers kvkkM">
          <h2>1 - VERİ SORUMLUSU KİMDİR</h2>
          <p>
            Veri Sorumlusu:Bursa Büyükşehir Belediye Başkanlığı (“Belediye”)
          </p>
          <p>
            Adresi:Zafer Mh. Ankara Yolu Cd. C Blok No:1 PK:16270 Osmangazi /
            BURSA
          </p>
          <p>Telefon:4441600</p>
          <p>KEP Adresi:bursabuyuksehir.genelevrak@hs03.kep.tr</p>

          <h2>2 - KİŞİSEL VERİLER HANGİ AMAÇLA İŞLENİR</h2>

          <h1>
            Gençlik ve Spor Hizmetleri Dairesi Başkanlığı
          </h1>
          <p>
            Bungalov Ev Konaklama Kayıt Listeleri, Ulusal/Uluslararsı
            Yarışmalar, Stant Karşılamaları , Gençlik Merkezi/Milli Kıraathane
            Giriş Kayıtları, Öğrenci Ziyaretleri, Büyükşehir Spor Okulları
            Kayıtları, BUSMEK Online Başvuru, Drama Eğitimleri, Gençlik Bursa’da
            Yollarda Faaliyetleri, Medya Okulu/Psikolojik Roman Okumaları,
            Sosyal Medya Hesapları Üzerinden İletişim, Organizasyonlara Verilen
            Çeşitli Destekler, Stant Konumlandırma İşlemleri, Kamp Değerlendirme
            Anket Formu, Spor Okulları Kayıt Formu, Yüzme Havuzları Kullanım
            Formu, Veli İzin Belgesi, İzci-Lider Bilgi Formu, Durum Bildirir Tek
            Hekim Raporu İçin Başvuru Sahibi Sağlık Bilgi Formu, Eğitim Kampı
            Kayıt Formu, Spor Tesisi Tahsisi Dilekçe, Seyahat Belgesi, Ders
            Katılımları/Online Ön Kayıt, Ders Katılımları/Kesin Kayıt, Ders
            Katılımları/Şahsen Kesin Kayıt Başvurusu, Ders
            Katılımları/Kazananlar İçin Duyuru, Türk Sanat Müziği Çocuk Korosu,
            Enstrüman Dersleri/Roman Korosu , Türk Sanat Müziği/Türk Halk Müziği
            Yoklama İşlemleri, Koro Gönüllüleri Dilekçeleri, Konser Etkinlikleri
            , Sanatçılar İçin Ödeme İşlemleri , Sözleşme İşlemleri, Menajer
            İşlemleri, Bilet Satış İşlemleri, Gişeden Bilet Satış İşlemleri,
            Gidilen Kurum/STK Etkinlikleri, Etkinlik/Dikkat Müzik Çıkabilir,
            Etkinlik, Canlı Yayın Konserleri, Canlı Yayın Konserleri/Ders
            Başlangıç SMS Gönderimi, Öğrenci Kartı Dilekçesi, Sertifika
            İşlemleri, Yordam Sistemi Üyeleri, Kütüphane Girişleri, Yordam
            Sistemi Destek İşlemleri, Konuk/Yazar İşlemleri, Açık Hava
            Kütüphanesi Kayıt İşlemleri, Açık Hava Kütüphanesi Yordam Sistemine
            Kayıt İşlemleri, Kültürel Sanatsal Aktiviteler, Üyelik Cezaları,
            Üyelik Güncelleme İşlemleri, Etkinlik Fotoğrafları/Sosyal Medya
            Hesapları, Kütüphane Kartı, Piknik Fotoğrafları, Tarihi Araştırma,
            Bağış İşlemleri, Arşiv/Belgesel Görüntüleri, Bursa'nın Tarihi
            Mahallelerinin/Çarşının Öyküsü, Ziyaretler/Röportajlar, Köy
            Ziyaretleri, Sergi Açma İşlemleri, Sergi Açma İzin Dilekçesi
            İşlemleri, Kendi Sergilerimiz, Sergiler, Ukrayna İcatları Sergiler,
            İsveç Büyükelçiliğiyle Ortak Sergi, Arkeoloji Kampı, Tarihi
            Yayınlar, Etkinlik Duyuruları Görüntüleri, Müze Denetimleri, Müze
            Ziyaretçileri, Pandemi Süreci, Pandemi Öncesi Ziyaretlerde, Ön Kayıt
            Formu Müze Ziyaretleri İçin Online/Randevu, Online
            Randevu/Rezervasyon İşlemleri, Denge Testi, Sergi Salonu Tahsisleri,
            Şehir Tiyatroları, Şehir Tiyatroları/Online Bilet Satışı, Şehir
            Tiyatroları/Gişeden Bilet Satışı, Sinema Gösterimi, Sinema Atölyesi,
            Tiyatro Kursu/Online Kayıt, Kültür Sanat Kurulu Salon Tahsisleri,
            Salon Tahsisleri / Dilekçe, Salon Tahsisleri, Araç Sineması, Yarışma
            Organizasyonları, Organizasyonlar , Kültür Merkezi Tanıtımı, Tiyatro
            Kurs Kayıt Formu, Etkinlik Onay Formu, Başvuru Dilekçesi, Kurumsal
            Bilgi Formu, Engelliler Meclisi Üye Ek Bilgileri, Kütüphane Kayıt
            Formu, Sanat Seminerleri Kayıt Formu
          </p>

          <h2>3 - KİŞİSEL VERİLER KİMLERE HANGİ AMAÇLA AKTARILABİLİR</h2>

          <p>
            Toplanan kişisel verileriniz bu aydınlatma metninin 2. maddesinde
            açıklanan amaçlarla gerekmesi halinde aşağıda belirtilen kuruluşlara
            aktarılacaktır.
          </p>
          <ul>
            <li>Bursa Büyükşehir Belediyesi şirket ve iştiraklerine</li>
            <li>Resmi kurum ve kuruluşlara</li>
            <li>
              Belediyecilik faaliyetlerini yürütmek ve yasal gereklilikler için
              hizmet aldığımız kişi ve kuruluşlara
            </li>
            <li>
              Belediye faaliyetlerini denetleyen kuruluşlara, bağımsız denetim
              firmalarına, danışmanlık firmalarına, hukuk firmalarına
            </li>
            <li>
              Belediye ve paydaşlar ile birlikte yürütülen süreçlerde paydaşlara
            </li>
          </ul>

          <h2>4 - KİŞİSEL VERİ TOPLAMANIN YÖNTEMİ VE HUKUKİ SEBEBİ</h2>
          <p>
            Web sistemleri, kameralar, kayıt cihazları, kiosklar, fotoğraf
            makineleri, online formlar, mobil uygulamalar, sosyal medya, baskı
            form materyalleri, yerel hizmet birimleri kayıt defterleri ile
            elektronik ve fiziksel ortamda yazılı sözlü, otomatik ve manuel
            olarak toplanır.
          </p>

          <h2>5 - İLGİLİ KİŞİ HAKLARI</h2>
          <p>
            Kişisel Verilerin Korunması Kanunu kapsamında aşağıdaki haklara
            sahipsiniz:
          </p>
          <p>1. Kişisel veri işlenip işlenmediğini öğrenme,</p>
          <p>2. Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
          <p>
            3. Kişisel verilerin işlenme amacını ve amacına uygun kullanılıp
            kullanılmadığını öğrenme,
          </p>
          <p>
            4. Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
            üçüncü kişileri bilme,
          </p>
          <p>
            5. Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
            bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
            kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme
          </p>
          <p>
            6. 6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak
            işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
            kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
            isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
            üçüncü kişilere bildirilmesini isteme,
          </p>
          <p>
            7. İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
            analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
            ortaya çıkmasına itiraz etme,
          </p>
          <p>
            8. Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğraması hâlinde zararın giderilmesini talep etme haklarına
            sahiptir.
          </p>
          <p>
            Kişisel Verilerin Korunması Kanununun 13.maddesinin 1.fıkrası
            gereğince Yukarıda belirtilen haklarınıza yönelik başvurularınızı
            belediyemize
            <a href="www.bursa.bel.tr">www.bursa.bel.tr</a>
            web sitesindeki
            <a
              href="https://www.bursa.bel.tr/dosyalar/galeri_x/1ghxn0lffev404g.pdf"
            >
              KVKK İlgili Kişi Başvuru Formu
            </a>
            aracılığı
            <a href="kvkk@bursa.bel.tr">kvkk@bursa.bel.tr</a>
            adresimize mail olarak iletebilirsiniz.
          </p>
          <p>Aynı zamanda;</p>
          <p>
            İadeli taahhütlü posta ile Zafer Mh. Ankara Yolu Cd. C Blok No:1
            PK:16270 Osmangazi / BURSA adresimize
            <a
              href="https://www.bursa.bel.tr/dosyalar/galeri_x/1ghxn0lffev404g.pdf"
            >
              KVKK İlgili Kişi Başvuru Formu
            </a>
            ile ya da kendi dilekçeniz ile,
          </p>
          <p>
            Kayıtlı Elektronik Posta ile
            <a href="bursabuyuksehir.genelevrak@hs03.kep.tr">
              bursabuyuksehir.genelevrak@hs03.kep.tr
            </a>
            adresimize,
          </p>
          <p>
            Zafer Mh. Ankara Yolu Cd. C Blok No:1 PK:16270 Osmangazi / BURSA
            adresinde mukim belediyemize şahsen gelerek başvuruda
            bulunabilirsiniz.
          </p>
        </section>
      </section>
    </v-container>
  </main>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

*::v-deep {
  .kvkkM {
    h1 {
      margin-bottom: 10px;
    }
    h2 {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    a {
      text-decoration: underline;
      font-weight: 800;
    }
  }
  .double-blob {
    position: relative;
    top: -600px;
    .blob-2 {
      left: -200px;
    }
    .blob-3 {
      top: 170px;
      left: auto;
      right: -50px;
    }
  }
  .blob-content {
    position: relative;
    left: -15px;
    margin: 0 auto;
    max-width: 660px;
    .text {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #006f7e;
      margin-bottom: 0;
    }
    .text-1 {
      letter-spacing: 0.05em;
      line-height: 25px;
    }
    .text-2 {
      margin-top: 100px;
      padding: 50px 0;
      text-align: center;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #ffcc00;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-top: 20px;
      }
    }
    .text-3 {
      padding: 50px 0;
      text-align: center;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #ffcc00;
    }
  }
  .number {
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    width: 60%;
    padding: 25px 0;
    h3 {
      font-family: 'Paytone One', sans-serif;
      font-size: 96px;
      line-height: 100%;
      margin-right: 16px;
      padding-bottom: 10px;
      color: white;
      text-shadow: -1px -1px 0 #cc0066, 1px -1px 0 #cc0066, -1px 1px 0 #cc0066,
        1px 1px 0 #cc0066;
    }
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #006f7e;
    }
  }
  .number-1 {
    position: relative;
    left: -80px;
  }
  .number-2 {
    position: relative;
    left: 40px;
  }
  .number-3 {
    position: relative;
    left: 0px;
  }

  .heading {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #cc0066;
    margin-bottom: 0px;
  }
  .content {
    margin-bottom: 130px;
  }
  .blob-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .blob-1 {
      position: absolute;
      left: auto;
      right: auto;
      top: 270px;
      width: 1400px;
      @media #{map-get($display-breakpoints, 'md-and-down')} {
        top: 170px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .blob-container {
      display: none;
    }
    .blob-content {
      width: 100%;
      left: auto;
    }
    .text {
      text-align: center;
    }
    .number {
      left: auto !important;
    }
    .blob-3 {
      display: none;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .number {
      left: auto !important;
      width: 100%;
      padding: 0;
    }
  }
}
</style>
