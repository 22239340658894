<template>
  <v-container v-if="paginated">
    <section>
      <v-row justify="space-between" align="center" class="mb-8">
        <v-col cols="auto">
          <h1 class="heading">{{ displayName }}</h1>
        </v-col>
        <v-col cols="auto">
          <pagination :name="name" v-if="!loading && paginated.total > 0" />
        </v-col>
      </v-row>
      <div v-if="paginated && paginated.total > 0">
        <section class="loading-container" v-if="loading">
          <v-progress-circular
            size="50"
            class="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </section>
        <section v-else>
          <slot :items="items" />
        </section>
      </div>
      <div v-else>
          <h4>
          Henüz kayıtlı bir {{singularName}} bulunmamaktadır.
          </h4>
      </div>
    </section>
  </v-container>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";

export default {
  components: { Pagination },
  mounted() {
    this.fetchItem(this.$route.params.page);
  },
  watch: {
    "$route.params.page"(newVal, oldVal) {
      if (newVal != oldVal) {
        this.fetchItem(newVal);
      }
    },
  },
  methods: {
    async fetchItem(page) {
      if (!page || page < 1) page = 1;
      const apiMod = await import("@/api/" + this.apiName);
      const api = apiMod.default;
      await this.$store.dispatch("paginated/fetch", {
        name: this.name,
        fetch: api.index(page, this.perPage),
      });
      if (page > this.paginated.maxPage) {
        this.$router.replace({ name: this.$route.name, params: { page: 1 } });
      }
    },
    momentDate(date) {
      if (date) {
        return moment(date).locale("tr").format("Do MMMM, YYYY");
      }
      return "";
    },
  },
  computed: {
    loading() {
      return this.$store.getters["paginated/isPaginatedLoading"](this.name);
    },
    paginated() {
      return this.$store.getters["paginated/getPaginated"](this.name);
    },
    items() {
      return this.paginated?.items;
    },
  },
  props: {
    perPage: Number,
    name: String,
    apiName: String,
    displayName: String,
    singularName: String,
  },
};
</script>

<style scoped>
.heading {
  font-size: 1.35rem;
  line-height: 21px;
  color: #cc0066;
  margin-bottom: 0;
}
.loading-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
</style>