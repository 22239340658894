<template>
  <v-carousel
    v-if="items && !loading"
    cycle
    :show-arrows-on-hover="true"
    class="slider"
    height="auto"
    
    
    
  >
    <v-carousel-item
      v-for="(slide, index) in items"
      :key="index"
      eager
      ripple
      
      :to="getRouteOf(slide)"
      
    >
    <v-img eager :src="slide.image" width="100%"/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import sliderApi from "@/api/slider";
export default {
  data() {
    return {
      items: [],
      loading: true,
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      try {
        this.loading = true;
        const res = await sliderApi.index(1, 10);
        this.items = res.data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getRouteOf(item) {
      if (item) {
        switch (item.type) {
          case "event":
            return { name: "event", params: { slug: item.event_slug } };
          case "campaign":
            return { name: "campaign", params: { slug: item.campaign_slug } };
          default:
            break;
        }
      }
      return { path: "/" };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.slider {
  border-radius: 24px;
  margin-bottom: 2rem;
}
*::v-deep {
  .v-carousel__controls {
    overflow: visible;
    background: none;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: none;
      }
  }
}
</style>