<template>
  <div>
    <v-navigation-drawer v-model="drawer" temporary absolute>
      <v-list nav dense>
        <v-list-item-group v-model="activeLink">
          <router-link
            v-for="link in links"
            :key="link.name"
            :to="{ name: link.router_name }"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item :class="'deep-purple--text text--accent-4'">
              <v-list-item-title>
                {{ link.name }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <header>
      
      <img class="logo--bg" :src="require('@/assets/blobs/1.svg')" contain />
      
      <v-container style="position: relative;">
        <v-row align="center" justify="space-between">
          <v-col cols="auto">
            <router-link :to="{path: '/'}">
            <section class="logo">
              <v-img
                
                class="logo--image"
                :src="require('@/assets/logo.png')"
                contain
              />
            </section>
            </router-link>
          </v-col>
          <v-col cols="auto" class="d-md-none">
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-col>
          <v-col cols="auto" class="d-none d-md-block">
            <nav class="main-nav">
              <ul>
                <li v-for="(link,key) of links" :key="key">
                  <router-link
                    :to="{ name: link.router_name }"
                    :class="activeLink == key ? 'active' : ''"
                    
                  >
                    {{ link.name }}
                  </router-link>
                </li>
              </ul>
            </nav>
          </v-col>
        </v-row>
      </v-container>
    </header>
  </div>
</template>

<script>
import links from "@/data/links";
export default {
  mounted() {
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    links() {
      return links;
    },
    activeLink: {
      cache: false,
      get() {
        let ct = 0;
        for (const link of this.links) {
          if (link.router_name == this.$route.name) {
            break;
          }
          ct++;
        }
        return ct;
      },
      set() {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

header {
  position: relative;
  z-index: 1;

  nav.main-nav {
    ul {
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;

      li {
        a {
          position: relative;
          top: 0;
          padding: 0 56px;
          color: #004751;
          text-decoration: none;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          &.active {
            &::before {
              background: #FFCE5B;
            }
          }
          &:hover {
            &::before {
              width: 40px;
              height: 40px;
              left: 5px;
            }
          }
          &::before {
            transition: ease-in-out 0.5s;
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            left: 0;
            top: -20px;
            z-index: -1;
            opacity: 0.6;
            background: #FFB5FF;
          }
        }
      }
    }
  }
  nav.main-nav {
    ul {
      li {
        a {
          padding: 0 20px;
        }
      }
    }
  }
  .logo--bg {
    position: absolute;
    left: 0;
    top: -155px;
    width: 553px;
    height: 605px;
    z-index: -1;
    animation: float 4s ease-in-out infinite;
  }
  .logo::v-deep {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    z-index: 1;
    .v-image.logo--image {
      height: 200px;
      width: auto;
      margin-right: 25px;
      flex-grow: 0;
    }

    h1 {
      color: #004751;
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-down')} {
  header {
    .logo::v-deep {
      left: 0;
      .v-image.logo--image {
      height: 100px;
    }
    }
    
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  header {
    margin-top: 15px;
    .logo::v-deep {
      position: relative;
      .v-image.logo--image {
        height: 80px;
        margin-right: 0;
      }
      h1 {
        font-size: 20px;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  header {
    .logo::v-deep {
      position: relative;
      .v-image.logo--image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
      }
      h1 {
        font-size: 12px;
      }
    }
  }
}
</style>