<template>
  <main class="p-relative">
    <v-snackbar v-model="snackbar" :timeout="2000" color="orange lighten-2">
      Mesajınız alındı

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Tamam
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="mb-2">
      <h1 class="heading">İletişim</h1>
    </v-container>
    <v-container>
      <div class="mapouter">
        <div class="gmap_canvas" v-show="mapLoaded">
          <iframe
            @load="mapIsLoaded"
            class="gmaps-canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.423412553745!2d29.05018921570166!3d40.199643476737116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca3e1ccebd059f%3A0xfd60cceeeec7dae9!2sMerinos%20AKKM!5e0!3m2!1sen!2str!4v1636021964218!5m2!1sen!2str"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
        <div v-show="!mapLoaded" class="gmaps-height">
          <v-skeleton-loader  class="gmaps-loading" type="image" tile></v-skeleton-loader>
          <v-skeleton-loader  class="gmaps-loading" type="image" tile></v-skeleton-loader>
          
        </div>
      </div>
    </v-container>
    <v-container>
      <v-row justify="center">
        <v-col cols="4" md="4">
          <img class="blob blob-4" :src="require('@/assets/blobs/3.svg')" />
          <div class="p-relative">
            <img class="blob blob-3" :src="require('@/assets/blobs/4.svg')" />

            <section class="contact-blob">
              <img class="blob-2" :src="require('@/assets/blobs/8.svg')" />
              <article>
                <nav class="contact-nav">
                  <ul>
                    <li>
                      <img
                        class="contact-nav--icon"
                        :src="require('@/assets/contact/marker.svg')"
                      />
                      <p>
                        Merinos AKKM Kuzey Ofisleri
                        <br />
                       1. Kat Osmangazi / BURSA
                      </p>
                    </li>
                    <li>
                      <img
                        class="contact-nav--icon"
                        :src="require('@/assets/contact/phone.svg')"
                      />
                      <p> (0224) 716 22 50</p>
                    </li>
                  </ul>
                </nav>
              </article>
            </section>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <section class="content content-blob">
            <div class="blob-container">
              <img class="blob-1" :src="require('@/assets/blobs/7.svg')" />
            </div>
            <form @submit.prevent="recaptcha" class="blob-content p-relative">
              <v-row>
                <v-col cols="12">
                  <h3 class="contact-heading">İletişim Formu</h3>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.fullname"
                    color="orange darken"
                    label="İsim Soyisim"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.email"
                    color="orange darken"
                    label="E-Posta"
                    type="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.phone"
                    color="orange darken"
                    label="Telefon"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.subject"
                    color="orange darken"
                    label="Konu"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.message"
                    no-resize
                    outlined
                    color="orange darken"
                    label="Mesaj"
                    :height="270"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                      <v-btn
                        :disabled="!buttonEnabled"
                        :loading="loading"
                        type="submit"
                        rounded
                        block
                        color="orange lighten-2 white--text"
                        large
                      >
                        Gönder
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </form>
          </section>
        </v-col>
      </v-row>
    </v-container>
    <img class="blob blob-5" :src="require('@/assets/blobs/4.svg')" />
  </main>
</template>

<script>
import axios from "@/plugins/get-axios";
export default {
  data() {
    return {
      snackbar: false,
      loading: false,
      mapLoaded: false,
      form: {
        fullname: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    mapIsLoaded() {
      console.log("oki");
      this.mapLoaded = true;
    },
    openSnackbar() {
      this.form.message = "";
      this.snackbar = true;
    },
    async recaptcha() {
      try {
        this.loading = true;
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha("form/contact");
        console.log(token);
        const res = await axios.post("message/contact", {
          fullname: this.form.fullname,
          email: this.form.email,
          phone: this.form.phone,
          subject: this.form.subject,
          message: this.form.message,
          recaptcha_token: token,
        });
      } finally {
        this.form.fullname = "";
        this.form.email = "";
        this.form.phone = "";
        this.form.subject = "";
        this.form.message = "";
        this.loading = false;
        this.snackbar = true;
      }
    },
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_GOOGLE_SITEKEY;
    },
    buttonEnabled() {
      return (
        this.form.fullname &&
        this.form.email &&
        this.form.phone &&
        this.form.subject &&
        this.form.message
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

*::v-deep {
  .blob-3 {
    width: 281px;
    top: 100px;
    left: -250px;
  }
  .blob-4 {
    width: 661px;
    top: 700px;
    left: auto;
    right: -270px;
  }
  .blob-5 {
    width: 700px;
    top: auto;
    bottom: -320px;
    left: 100px;
  }
  .contact-heading {
    color: #006f7e;
  }
  .contact-nav {
    ul {
      li {
        display: flex;
        align-items: flex-start;
        .contact-nav--icon {
          position: relative;
          top: 5px;
          width: 32px;
          margin-right: 16px;
        }
      }
      padding: 0;
      margin: 0;
      list-style: none;
      p,
      a {
        position: relative;
        top: 5px;
        margin-bottom: 10px;
        color: #006f7e;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
  .contact-blob {
    position: relative;
    top: 220px;
    .blob-2 {
      top: -260px;
      left: -200px;
      position: absolute;
    }
    article {
      min-height: 300px;
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
      left: -90px;
    }
  }
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: #ffcc00 !important;
    border-width: 1px !important;
  }
  .v-input__control > .v-input__slot:before {
    border-color: #ffcc00 !important;
    border-width: 1px !important;
  }

  .gmaps-height {
    height: 500px;
  }

  .gmaps-canvas {
    @extend .gmaps-height;
    border-radius: 2rem;
    overflow: hidden;
    //background: none !important;
    
    width: 100%;
  }
  .content {
    margin-top: 120px;
    position: relative;
    width: 100%;
  }
  .content-blob {
    padding-top: 40px;
    min-height: 800px;
    right: -240px;
  }
  .blob-container {
    position: absolute;
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    .blob-1 {
      position: absolute;
      left: auto;
      right: auto;
      top: -240px;
      width: 1200px;
    }
  }
  .blob-content {
    left: -15px;
    margin: 0 auto;
    margin-top: -40px;
    max-width: 400px;

    width: 100%;
    display: flex;
    justify-content: center;
    .text {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #006f7e;
      margin-bottom: 0;
    }
  }

  .heading {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #cc0066;
    margin-bottom: 20px;
  }
  .content {
    margin-bottom: 180px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-down')} {
    .blob-4 {
      width: 661px;
      top: 700px;
      left: auto;
      right: -450px;
    }
    .contact-blob {
      article {
        position: relative;
        left: 0px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .contact-blob {
      top: 110px;
      margin-bottom: 200px;
    }
    .content-blob {
      left: auto;
      right: auto;
      article {
        position: relative;
        left: -70px;
      }
    }
    .blob-content {
      left: 0;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .blob-3 {
      width: 281px;
      top: -100px;
      left: -250px;
    }

    .contact-blob {
      .blob-2 {
        top: -260px;
        left: -280px;
      }
      article {
        position: relative;
        left: -70px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .blob-4 {
      display: none;
    }
  }
}
</style>
