<template>
  <router-link
    v-if="item"
    :to="{ name: 'event', params: { slug: item.slug } }"
  >
    <click-card
      :title="item.title"
      :highlight="item.location_adress"
      :text="item.description"
      :background="item.image_card"
    />
  </router-link>
</template>

<script>
import ClickCard from "@/components/ClickCard";
import daysMixin from "@/mixins/days-mixin";
import momentDate from "@/mixins/moment-date";
export default {
  components: { ClickCard },
  mixins: [daysMixin,momentDate],
  mounted() {},
  watch: {},
  methods: {
  },
  props: {
    item: Object,
  },
};
</script>

<style>
</style>