<template>
  <div class="parent">
    <v-icon class="slider-btn left" x-large @click="slidePrev"
      >mdi-chevron-left</v-icon
    >
    <v-icon class="slider-btn right" x-large @click="slideNext"
      >mdi-chevron-right</v-icon
    >
    <v-progress-circular
      v-if="loading"
      class="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>

    <VueSlickCarousel v-else class="sizer" v-bind="config" ref="carousel">
      <slot />
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: { VueSlickCarousel },
  props: {
    config: Object,
    loading: Boolean,
  },
  mounted() {
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.prev();
    },
    slideNext() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.parent {
  position: relative;
}
.loading {
  position: relative;
  left: 50%;
  left: calc(50% -16px);
  height: 170px !important;

}
.sizer {
  margin: 0 auto;
  width: 100%;
}
*::v-deep {
  .slider-btn {
    position: absolute;
    z-index: 1000000;
    color: black;
    font-size: 60px !important;
    line-height: 0;
    top: 50%;
    left: -70px;
    &.right {
      left: auto;
      right: -70px;
    }
  }

  /* the slides */
  .slick-slider {
    overflow: visible;
  }
  /* the parent */
  .slick-list {
    margin: 0 -9px;
  }
  /* item */
  .slick-slide {
    padding: 0px 9px;
  }
}
</style>