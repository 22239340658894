import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

function importView(path) {
  return require('@/views/' + path).default;
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: importView('Home')
  },
  {
    path: '/hakkimizda',
    name: 'about',
    component: importView('About')
  },
  {
    path: '/kvkk',
    name: 'kvkk',
    component: importView('KVKK')
  },
  {
    path: '/iletisim',
    name: 'contact',
    component: importView('Contact')
  },
  {
    path: '/basvuru',
    name: 'application',
    component: importView('Application')
  },
  {
    path: '/haberler/:page?',
    name: 'articles',
    component: importView('Articles'),
    props: { name: 'articles', displayName: 'Haberler', singularName: "haber", perPage: 6, apiName: 'article' }
  },
  {
    path: '/haber/:slug',
    name: 'article',
    component: importView('Article')
  },
  {
    path: '/firsatlar/:page?',
    name: 'campaigns',
    component: importView('Campaigns'),
    props: { name: 'campaigns', displayName: 'Fırsatlar', singularName: "fırsat", perPage: 8, apiName: 'campaign' }
  },
  {
    path: '/firsat/:slug',
    name: 'campaign',
    component: importView('Campaign')
  },
  {
    path: '/tesisler/:page?',
    name: 'locations',
    component: importView('Locations'),
    props: { name: 'locations', displayName: 'Tesisler', singularName: "tesis", perPage: 8, apiName: 'location' }
  },
  {
    path: '/tesis/:slug',
    name: 'location',
    component: importView('Location')
  },
  {
    path: '/etkinlikler/:page?',
    name: 'events',
    component: importView('Events'),
    props: { name: 'events', displayName: 'Etkinlikler', singularName: "etkinlik", perPage: 8, apiName: 'event' }
  },
  {
    path: '/etkinlik/:slug',
    name: 'event',
    component: importView('Event')
  },
  {
    path: "*",
    redirect: "/"
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to && from && to.name == from.name) {
      return savedPosition;
    }
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

export default router
