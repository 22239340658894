import moment from "moment";
export default {

    methods: {
        momentDate(date) {
            if (date) {
              return moment(date).locale("tr").format("Do MMMM, YYYY");
            }
            return "";
          },
    }
}