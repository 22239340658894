<template>
  <div>
   <v-row class="mb-8" justify="space-between" align="center">
      <v-col cols="auto">
        <h1 class="heading mb-0">Öne Çıkan Fırsatlar</h1>
      </v-col>
      <v-col cols="auto" v-if="campaigns && campaigns.length > 0">
        <router-link :to="{ name: 'campaigns' }"> Tümünü Gör </router-link>
      </v-col>
    </v-row>

    <slick-carousel
      :config="slick"
      :loading="loading"
      v-if="campaigns && campaigns.length > 0"
    >
      <campaign
        v-for="(campaign, key) of campaigns"
        :key="key"
        :item="campaign"
      />
    </slick-carousel>
    <div class="nothing" v-else>
      <h4 class="ml-2">Henüz kayıtlı bir fırsat bulunmamaktadır.</h4>
    </div>
  </div>
</template>

<script>
import Campaign from "@/components/Campaign";
import SlickCarousel from "@/components/SlickCarousel";
import campaignApi from "@/api/campaign";
import moment from "moment";
export default {
  components: { Campaign, SlickCarousel },
  mounted() {
    this.fetchCampaigns();
  },
  data() {
    return {
      loading: true,
      campaigns: null,

      slick: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    momentDate(date) {
      if (date) {
        return moment(date).locale("tr").format("Do MMMM, YYYY");
      }
      return "";
    },
    async fetchCampaigns() {
      try {
        this.loading = true;
        const res = await campaignApi.index(1, 8);
        this.campaigns = res.data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    goToLink(slug) {
      this.$router.push({ name: "campaign", params: { slug: slug } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>