
import axios from '@/plugins/get-axios';
const URL_EVENT_INDEX = '/events';
const URL_EVENT_READ = '/events/{slug}';


function index(page,per_page=4) {
    return axios.get(URL_EVENT_INDEX, {
        params: {
            page,per_page
        }
    });
}

function read(slug) {
    return axios.get(URL_EVENT_READ.replace('{slug}',slug), {});
}

export default { index,read };
export { URL_EVENT_INDEX, URL_EVENT_READ };