<template>
      <v-hover>
 <template v-slot:default="{ hover }">
  <v-card :class="`card cursor transition-swing elevation-${hover ? 4 : 2}`">
    <v-responsive aspect-ratio="4" height="230" max-height="230" @click.stop="$emit('activate')">
      <v-img class="image" :src="getImgUrl(background)" />
      <v-card-text>
        <v-row class="flex-nowrap">
          <v-col>
            <h4 v-if="title">{{ title }}</h4>
          </v-col>
          <v-col cols="auto">
            <h6 v-if="highlight">{{ highlight }}</h6>
          </v-col>
        </v-row>
      </v-card-text>
    </v-responsive>
  </v-card>
        </template>

      </v-hover>

</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    
  },
  props: {
    title: String,
    background: String,
    highlight: String,
  },
  methods: {
    getImgUrl(url) {
      return url;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.card {
  width: 100%;
  border-radius: 10px;
}
.cursor {
  cursor: pointer;
}
*::v-deep {
  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #006f7e;
    margin-bottom: 5px;
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #cc0066;
  }

  .image {
    height: 70%;
    object-fit: cover;
  }
}
</style>