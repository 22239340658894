import Vue from 'vue';
export default {
    namespaced: true,
    state: () => ({

    }),
    mutations: {
        setPaginated(state, { name, page, total, items,maxPage }) {
            Vue.set(state,name, {
                loading: false,
                page: page,
                total: total,
                items: items,
                maxPage: maxPage
            })
            state[name] =  {
                loading: false,
                page: page,
                total: total,
                items: items,
                maxPage: maxPage
            };
          
        },
        setLoading(state, { name, loading }) {
            if (state[name]) {
                Vue.set(state,name+'.loading',loading);
                state[name].loading = loading;
            }
        }
    },
    actions: {
        /**
         * 
         * @param {Object} param0 
         * @param {Promise} fetch 
         */
        async fetch({ state, commit, rootState }, {name, fetch}) {
            try {
                await commit('setLoading', { name: name, loading: true });
                const res = await fetch;
                const data = res.data.data;
                await commit('setPaginated', {
                    name: name,
                    page: data.current_page,
                    total: data.total,
                    items: data.data,
                    maxPage: data.last_page
                })
            }
            finally {
                await commit('setLoading', { name: name, loading: false });
            }

        }
    },
    getters: {
        isPaginatedLoading: (state) => (name) => {
            if(state[name])
                return state[name].loading;
            return null;
        },
        getPaginated: (state) => (name) => {
            if(state[name])
                return state[name];
            return null;
        },
    }
}