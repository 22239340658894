<template>
  <v-container>
    <v-progress-circular
      v-if="loading"
      class="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <section v-else>
      <v-row justify="center">
        <v-col  lg="9" xl="8">
      
      <h1 class="mb-8">{{ item.title }}</h1>
      <v-img class="mb-8" :src="item.image_detail"></v-img>
      <p class="mb-4 info-text" v-if="true==false">
        {{momentDate(item.start_date)}} - {{momentDate(item.end_date)}}
        </p>
      <p class="mb-8 info-text">{{item.description}}</p>

      <article v-html="item.content"></article>
  <article v-html="item.address"></article>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import locationApi from "@/api/location";
import momentDate from "@/mixins/moment-date";

export default {
  mixins: [momentDate],
  components: {},
  watch: {
    "$route.params.slug"(newVal) {
    this.fetchItem(newVal);  
    
    },
  },
  mounted() {
    this.fetchItem(this.$route?.params?.slug);
  },
  data() {
    return {
      loading: true,
      item: null,
      
    };
  },
  methods: {
    async fetchItem(slug) {
      if (slug) {
        try {
          this.loading = true;
          const res = await locationApi.read(slug);
          this.item = res.data.data;
        } catch (error) {
          if(error && error.response && error.response.status === 404) {
            this.$router.push({name: 'home'});
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .info-text {
      color: #cc0066;


  }
</style>
