
import axios from '@/plugins/get-axios';
const URL_SLIDER_INDEX = '/sliders';


function index(page,per_page=10) {
    return axios.get(URL_SLIDER_INDEX, {
        params: {
            page,per_page
        }
    });
}


export default { index};
export { URL_SLIDER_INDEX};