<template>
  <v-hover>
  <template v-slot:default="{ hover }">
    <v-card :class="`card cursor transition-swing elevation-${hover ? 4 : 2}`">
      <v-responsive :aspect-ratio="0.7">
        <v-img class="white--text align-end image" :src="background"> </v-img>

        <v-card-text>
          <h4 v-if="title">{{ title }}</h4>
          <h5 v-if="subtitle">{{ subtitle }}</h5>
          <h6 v-if="highlight">{{ highlight }}</h6>
          <p v-if="text">{{ text }}</p>
        </v-card-text>
      </v-responsive>
    </v-card>
  </template>
  </v-hover>
</template>

<script>
export default {
  props: ["title", "subtitle", "highlight", "text", "background"],
  methods: {
    getImgUrl(url) {
      return require("@/" + url + "");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.card {
  height: 100%;
  border-radius: 10px;
}
.cursor {
  cursor: pointer;
}
*::v-deep {
  h4 {
    display: inline-block;
    min-height: 38px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #006f7e;
    margin-bottom: 3px;
  }
  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 7px;
    color: #006f7e;
  }
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
    color: #cc0066;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #004751;
  }

  .image {
    height: 40%;
    object-fit: cover;
  }
}
</style>