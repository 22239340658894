import { render, staticRenderFns } from "./ClickCard.vue?vue&type=template&id=5acad521&scoped=true&"
import script from "./ClickCard.vue?vue&type=script&lang=js&"
export * from "./ClickCard.vue?vue&type=script&lang=js&"
import style0 from "./ClickCard.vue?vue&type=style&index=0&id=5acad521&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5acad521",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VCard,VCardText,VHover,VImg,VResponsive})
