<template>
  <div>
   <v-row class="mb-8" justify="space-between" align="center">
      <v-col cols="auto">
        <h1 class="heading mb-0">Öne Çıkan Tesisler</h1>
      </v-col>
      <v-col cols="auto" v-if="locations && locations.length > 0">
        <router-link :to="{ name: 'locations' }"> Tümünü Gör </router-link>
      </v-col>
    </v-row>

    <slick-carousel :config="slick" :loading="loading" v-if="locations && locations.length > 0">
      <location
        v-for="(event, key) of locations"
        :key="key"
        :item="event"
      />
    </slick-carousel>
    <div class="nothing" v-else>
        <h4 class="ml-2">Henüz kayıtlı bir etkinlik bulunmamaktadır.</h4>
    </div>
  </div>
</template>

<script>
import Location from "@/components/Location";
import SlickCarousel from "@/components/SlickCarousel";
import LocationApi from "@/api/location";

export default {
  components: { Location, SlickCarousel},
  mounted() {
    this.fetchLocation();
  },
  data() {
    return {
      loading: true,
      locations: null,

      slick: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {

  },
  methods: {
  
    async fetchLocation() {
      try {
        this.loading = true;
        const res = await LocationApi.index(1, 8);
        this.locations = res.data.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>