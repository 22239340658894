<template>
  <v-container>
    <v-progress-circular
      v-if="loading"
      class="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <section v-else>
      <v-row justify="center">
        <v-col  lg="9" xl="8">

      <h1 class="mb-8">{{ item.title }}</h1>
      <slick-carousel :config="slick" :loading="loading" class="mb-8">
        <v-img :aspect-ratio="2"  v-for="(image,key) of item.article_images" :key="key" :src="image.image"></v-img>
      </slick-carousel>
      <article v-html="item.content"></article>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import articleApi from "@/api/article";
import SlickCarousel from "@/components/SlickCarousel";

export default {
  components: {SlickCarousel},
  watch: {
    "$route.params.slug"(newVal) {
    this.fetchItem(newVal);  
    
    },
  },
  mounted() {
    this.fetchItem(this.$route?.params?.slug);
  },
  data() {
    return {
      loading: true,
      item: null,
      slick: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",

        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: "ondemand",
      }
    };
  },
  methods: {
    async fetchItem(slug) {
      if (slug) {
        try {
          this.loading = true;
          const res = await articleApi.read(slug);
          this.item = res.data.data;
        } catch (error) {
          if(error && error.response && error.response.status === 404) {
            this.$router.push({name: 'home'});
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style>
</style>