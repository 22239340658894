
import axios from '@/plugins/get-axios';
const URL_ARTICLE_INDEX = '/articles';
const URL_ARTICLE_READ = '/articles/{slug}';


function index(page,per_page=4) {
    return axios.get(URL_ARTICLE_INDEX, {
        params: {
            page,per_page
        }
    });
}

function read(slug) {
    return axios.get(URL_ARTICLE_READ.replace('{slug}',slug), {});
}

export default { index,read };
export { URL_ARTICLE_INDEX, URL_ARTICLE_READ };