<template>
  <v-pagination
    v-model="page"
    :length="paginated.maxPage"
    :total-visible="7"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    name: String,
  },
  computed: {
    page: {
      get() {
        return this.paginated.page;
      },
      set(val) {
        if (Number.isInteger(val)) {
          this.$router.push({ name: this.$route.name, params: { page: val } });
        }
      },
    },
    loading() {
      return this.$store.getters["paginated/isPaginatedLoading"](this.name);
    },
    paginated() {
      return this.$store.getters["paginated/getPaginated"](this.name);
    },
    items() {
      return this.paginated?.items;
    },
  },
};
</script>

<style>
</style>