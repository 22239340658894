<template>
  <router-link
    :to="{ name: 'campaign', params: { slug: item.slug } }"
    v-if="item"
  >
    <click-card
      :title="item.title"
      :highlight="item.discount_description"
      :text="item.description"
      :background="item.image_card"
    />
  </router-link>
</template>

<script>
import ClickCard from "@/components/ClickCard";
import daysMixin from "@/mixins/days-mixin";
import momentDate from "@/mixins/moment-date";
export default {
  mixins: [daysMixin,momentDate],
  components: { ClickCard },
  mounted() {},
  watch: {},
  methods: {
   
  
  },
  props: {
    item: Object,
  },
};
</script>

<style>
</style>