<template>
  <main>
    <v-container class="mb-2">
      <h1 class="heading">Hakkımızda</h1>
    </v-container>
    <v-container class="content">
      <div class="blob-container">
        <img class="blob-1" :src="require('@/assets/blobs/7.svg')" />
      </div>
      <section class="blob-content p-relative">
        <p class="text text-1 text-center text-sm-justify">
          Büyükşehir Belediyesi olarak dönem içerisinde gençlik odaklı
          gerçekleştirilen pek çok faaliyetten ayrı ayrı sağlanan pozitif
          yansımaların, Bursa Büyükşehir Gençlik Kulübü markası altında daha
          güçlü bir etkileşim alanına ulaşması da gerçekleşmiş olacaktır.
        </p>
        <p class="text text-2">Siz gençlere sunacağımız avantajlar:</p>
        <section class="numbers">
          <article class="number number-1">
            <h3>1</h3>
            <p>
              İndirimler. (Büyükşehir imkanları; ulaşımda ilave indirim, sosyal
              tesis ilave indirim vb., anlaşmalı mağaza ve yerlerden üyelere
              sağlanacak indirimler)
            </p>
          </article>
          <article class="number number-2">
            <h3>2</h3>
            <p>
              Kulüp üyelerinden yapılacak çekilişle yurtdışı seyahatleri ve yine
              hayal ettikleri isimlerle buluşma vb.
            </p>
          </article>
          <article class="number number-3">
            <h3>3</h3>
            <p>Mobil data paketleri</p>
          </article>
        </section>
        <p class="text text-3">ve daha fazlası için takipte kal!</p>
      </section>
    </v-container>
    <v-container class="double-blob">
              <img class="blob blob-2" :src="require('@/assets/blobs/4.svg')" />
              <img class="blob blob-3" :src="require('@/assets/blobs/3.svg')" />

    </v-container>
  </main>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

*::v-deep {
  .double-blob {
    position: relative;
    top: -600px;
    .blob-2 {
      left: -200px;
    }
    .blob-3 {
      top: 170px;
      left: auto;
      right: -50px;
    }
  }
  .blob-content {
    position: relative;
    left: -15px;
    margin: 0 auto;
    max-width: 660px;
    .text {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #006f7e;
      margin-bottom: 0;
    }
    .text-1 {
      letter-spacing: 0.05em;
      line-height: 25px;
      
    }
    .text-2 {
      margin-top: 100px;
      padding: 50px 0;
      text-align: center;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #ffcc00;
       @media #{map-get($display-breakpoints, 'sm-and-down')} {
         margin-top: 20px;
       }
    }
    .text-3 {
      padding: 50px 0;
      text-align: center;
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #ffcc00;
    }
  }
  .number {
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    width: 60%;
    padding: 25px 0;
    h3 {
      font-family: 'Paytone One', sans-serif;
      font-size: 96px;
      line-height: 100%;
      margin-right: 16px;
      padding-bottom: 10px;
      color: white;
      text-shadow: -1px -1px 0  #cc0066, 1px -1px 0  #cc0066, -1px 1px 0 #cc0066, 1px 1px 0  #cc0066;
    }
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #006f7e;
    }
  }
  .number-1 {
    position: relative;
    left: -80px;
  }
  .number-2 {
    position: relative;
    left: 40px;
  }
  .number-3 {
    position: relative;
    left: 0px;
  }

  .heading {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #cc0066;
    margin-bottom: 0px;
  }
  .content {
    margin-bottom: 130px;
  }
  .blob-container {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .blob-1 {
      position: absolute;
      left: auto;
      right: auto;
      top: 270px;
      width: 1400px;
        @media #{map-get($display-breakpoints, 'md-and-down')} {
        top: 170px;
        }
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .blob-container {
      display: none;
    }
    .blob-content {
      width: 100%;
      left: auto;
    }
    .text {
      text-align: center;
    }
    .number {
      left: auto !important;
    }
       .blob-3 {
     display: none;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
 
    .number {
      left: auto !important;
      width: 100%;
      padding: 0;
    }
  }
}
</style>
