const links = [
    {
        name: 'Anasayfa',
        router_name: 'home',
    },
    {
        name: 'Hakkımızda',
        router_name: 'about',
    },
    {
        name: 'Fırsatlar',
        router_name: 'campaigns',
    },
    {
        name: 'Tesisler',
        router_name: 'locations',
    },
    {
        name: 'Etkinlikler',
        router_name: 'events',
    },
    {
        name: 'Haberler',
        router_name: 'articles',
    },
    {
        name: 'Sağlayıcı Başvurusu',
        router_name: 'application',
    },
    {
        name: 'İletişim',
        router_name: 'contact',
    },
   

];

export default links;