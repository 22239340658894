<template>
  <div>
    <footer>
      <main>
        <v-container>
          <v-row
            align="start"
            align-md="center"
            justify="space-around"
            justify-md="start"
            class="full-height"
          >
            <v-col class="p-relative line-height" cols="auto" md="5" lg="4">
              <div class="dash-to-right"></div>
              <section class="logo">
                <router-link :to="{name: 'home'}">

                <img class="logo--image" :src="require('@/assets/logo-dark.png')" />
                </router-link>
                <div class="download">
                  <a href="https://play.google.com/store/apps/details?id=com.bbb_genclik_kulubu_app&gl=TR" class="d-inline-block mb-2">
                    <v-img :src="require('@/assets/downloads/google.png')"></v-img>
                  </a>
                  <a href="https://apps.apple.com/us/app/bursabüyükşehir-gençlik-kulübü/id1556308269">
                    <v-img :src="require('@/assets/downloads/apple.png')"></v-img>
                  </a>
                </div>
              </section>
            </v-col>
            <v-col class="p-relative line-height" cols="auto" md>
              <div class="dash-to-right"></div>
              <nav class="main-nav">
                <ul>
                  <li v-for="link in links" :key="link.name">
                    <router-link
                      :to="{ name: link.router_name }"
                      active-class="active"
                      exact
                    >
                      {{ link.name }}
                    </router-link>
                  </li>
                </ul>
              </nav>
            </v-col>
            <v-col class="p-relative line-height" cols="auto" md>
              <div class="dash-to-right"></div>
              <nav class="social-nav">
                <ul>
                  <li>
                    <img
                      class="social-nav--icon"
                      :src="require('@/assets/socials/instagram.svg')"
                    />
                    <a href="https://instagram.com/bbgenclikkulubu">/bbgenclikkulubu</a>
                  </li>
                  <li>
                    <img
                      class="social-nav--icon"
                      :src="require('@/assets/socials/facebook.svg')"
                    />
                    <a href="https://www.facebook.com/bbgenclikkulubu/">/bbgenclikkulubu</a>
                  </li>
                  <li>
                    <img
                      class="social-nav--icon"
                      :src="require('@/assets/socials/twitter.svg')"
                    />
                    <a href="https://twitter.com/bbgenclikkulubu">/bbgenclikkulubu</a>
                  </li>
                  <li>
                    <img
                      class="social-nav--icon"
                      :src="require('@/assets/socials/youtube.svg')"
                    />
                    <a href="https://youtube.com/channel/UCDoKHb_nCVWEH0giO89fU9g">/bbgenclikkulubu</a>
                  </li>
                </ul>
              </nav>
            </v-col>
            <v-col cols="auto" md="3">
              <nav class="contact-nav">
                <ul>
                  <li>
                    <img
                      class="contact-nav--icon"
                      :src="require('@/assets/contact/marker.svg')"
                    />
                    <p>
                      Zafer Mah. Ankara yolu Caddesi.<br />
                      No: 1 16080 Osmangazi/BURSA
                    </p>
                  </li>
                  <li>
                    <img
                      class="contact-nav--icon"
                      :src="require('@/assets/contact/phone.svg')"
                    />
                    <p>444 16 00 / ALO 153</p>
                  </li>
                </ul>
              </nav>
            </v-col>
          </v-row>
        </v-container>
      </main>
      
    </footer>
  </div>
</template>

<script>
import links from "@/data/links";
export default {
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    links() {
      return links;
    },
    activeLink: {
      get() {
        let ct = 0;
        for (const link of this.links) {
          if (link.router_name == this.$route.name) {
            break;
          }
          ct++;
        }
        return ct;
      },
      set() {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
*::v-deep {
  .logo {
    display: flex;
    align-items: center;
    .logo--image {
      width: 150px;
      
    }
    .download {
      margin-left: 15px;
    }
  }
}
footer {
  .main-nav {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      a {
        margin-bottom: 5px;
        color: #006f7e;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }

  .social-nav {
    ul {
      li {
        display: flex;
        align-items: center;
        .social-nav--icon {
          width: 16px;
          margin-right: 8px;
        }
      }
      padding: 0;
      margin: 0;
      list-style: none;
      a {
        margin-bottom: 5px;
        color: #006f7e;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
  .contact-nav {
    ul {
      li {
        display: flex;
        align-items: flex-start;
        .contact-nav--icon {
          position: relative;
          top: 5px;
          width: 16px;
          margin-right: 8px;
        }
      }
      padding: 0;
      margin: 0;
      list-style: none;
      p,
      a {
        margin-bottom: 5px;
        color: #006f7e;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
  .full-height {
    min-height: 250px;
  }
  .line-height {
    min-height: 180px;
  }
  .dash-to-right {
    position: absolute;
    right: -1px;
    top: 0px;
    width: 2px;
    height: 160px;
    background: #ffcc00;
  }
  position: relative;
  margin-top: 80px;
  z-index: 1;
  main {
    background: white;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -3px 14px rgba(0, 0, 0, 0.25);
  }
  & > section {
    font-size: 14px;
    line-height: 100%;
    padding:  5px 0;
    background: #79b1b8;
    color: white;
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        color: whitesmoke;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dash-to-right {
    display: none;
  }
}
</style>